import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"


class InfoPage extends React.Component {

  render() {

    const rFreeKicks = this.props.data.allSheetRonaldoAllTimeStats.edges.map(d => d.node.freeKicks)

    return (

      <Layout>
        <SEO
          title={"Info Regarding Messi and Ronaldo stats"}
          description={`More details into our stats and where other websites and stats providers have made mistakes - learn why Transfermarkt is a patchy source at best.`}
          canonicalPath={`/info/`}
        />

        <h1>Help and Advice</h1>

        

        <div class="mt-8 lg:mt-12">
          <CopyBlock>
            <h2>Issues with other stats sites</h2>

            <p>Read about the issues with other websites and stats providers, and where they've gone wrong regarding Lionel Messi and Cristiano Ronaldo stats. I'll explain why Opta's word isn't quite gospel, and why Transfermarkt is a patchy source at best.</p>

            <h2>Issues with Transfermarkt</h2>

            <p>Here are the known issues on Transfermarkt - there may be more we haven't yet caught.</p>

            <h3>Free Kicks</h3>
            <ul className="list-disc list-outside pl-4">
              <li>Missing a Ronaldo free kick goal vs Portsmouth in 04/11/2006</li>
              <li>They count Ronaldo’s goal vs Aston Villa on 05/04/2009 as a direct free kick goal - this was actually an indirect free kick inside the box - Ryan Giggs rolled the free kick to Ronaldo who then scored. Ronaldo also scored one like this vs Malaga, but Transfermarkt do not count that one.</li>
            </ul>

            <h3>Penalties</h3>
            <ul className="list-disc list-outside pl-4">
              <li>Missing a Messi penalty goal vs Switzerland 29/02/2012</li>
              <li>Includes 2 Messi penalties scored in the U20 World Cup (these don’t count toward official senior stats)</li>
              <li>Missing a Ronaldo penalty miss vs Valencia 29/04/2017</li>
              <li>When viewing a list of penalty goals/misses, the International “seasons” are slightly messed up - e.g. penalties taken in 2013 are listed as part of the 2011/12 season</li>
            </ul>

            <h3>Assists - Ronaldo</h3>

            <p>The most widely accepted assist definition in football is most certainly Opta's, which states that the last touch leading to a team mate scoring a goal is an assist. There is another brand of assist though which Opta collect as "Fantasy Assists" - these include deflected passes, rebounded shots, own goal assists and penalties won. Transfermarkt uses this looser definition for their assist stats, however they are extremely inconsistent with their application of this definition, missing many from their records.</p>

            <p>List of Ronaldo assists that Transfermarkt are missing.</p>

            <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead className="text-left">
                <tr>
                  <th>Date</th>
                  <th>Opponent</th>
                  <th>Competition</th>
                  <th>Assist Type</th>
                  <th>Missing / Incorrect Inclusion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30/10/2002</td>
                  <td>Partizan Belgrade</td>
                  <td>UEFA Cup</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>14/12/2002</td>
                  <td>Leiria</td>
                  <td>Liga NOS</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>20/08/2003</td>
                  <td>Kazakhstan</td>
                  <td>Int. Friendly</td>
                  <td>N/A</td>
                  <td><span role="img" aria-label="red cross">❌</span> Incorrect inclusion (defender clears and it bounces in off Simao - Ronaldo not involved)</td>
                </tr>
                <tr>
                  <td>14/02/2004</td>
                  <td>Man City</td>
                  <td>FA Cup</td>
                  <td>N/A</td>
                  <td><span role="img" aria-label="red cross">❌</span> Incorrect inclusion (team mate gets final touch)</td>
                </tr>
                <tr>
                  <td>26/12/2004</td>
                  <td>Bolton</td>
                  <td>Premier League</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>14/02/2005</td>
                  <td>Northern Ireland</td>
                  <td>Int. Friendly</td>
                  <td>Own goal assist</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>29/09/2018</td>
                  <td>Napoli</td>
                  <td>Serie A</td>
                  <td>Post reound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>10/02/2019</td>
                  <td>Sassuolo</td>
                  <td>Serie A</td>
                  <td>GK reound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>22/12/2019</td>
                  <td>Lazio</td>
                  <td>Supercoppa Italiana</td>
                  <td>GK reound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>26/07/2020</td>
                  <td>Sampdoria</td>
                  <td>Serie A</td>
                  <td>GK reound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>20/09/2020</td>
                  <td>Sampdoria</td>
                  <td>Serie A</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Assists - Messi</h3>

          <p>List of Messi assists that Transfermarkt are missing.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead className="text-left">
                <tr>
                  <th>Date</th>
                  <th>Opponent</th>
                  <th>Competition</th>
                  <th>Assist Type</th>
                  <th>Missing / Incorrect Inclusion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>11/05/2008</td>
                  <td>Mallorca</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>11/05/2008</td>
                  <td>Mallorca</td>
                  <td>La Liga</td>
                  <td>GK rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>25/10/2008</td>
                  <td>Almeria</td>
                  <td>La Liga</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>04/05/2010</td>
                  <td>Tenerife</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>08/05/2010</td>
                  <td>Sevilla</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>07/11/2010</td>
                  <td>Getafe</td>
                  <td>La Liga</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>07/11/2010</td>
                  <td>Getafe</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>19/02/2012</td>
                  <td>Valencia</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>20/03/2012</td>
                  <td>Granada</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>22/09/2012</td>
                  <td>Granada</td>
                  <td>La Liga</td>
                  <td>Own goal assist</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>10/02/2013</td>
                  <td>Getafe</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>14/09/2013</td>
                  <td>Sevilla</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>02/03/2014</td>
                  <td>Almeria</td>
                  <td>La Liga</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>31/08/2014</td>
                  <td>Villarreal</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>09/05/2015</td>
                  <td>Real Sociedad</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>18/12/2016</td>
                  <td>Espanyol</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>05/04/2017</td>
                  <td>Sevilla</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>23/04/2017</td>
                  <td>Real Madrid</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>29/04/2017</td>
                  <td>Espanyol</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>19/09/2017</td>
                  <td>Eibar</td>
                  <td>La Liga</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>18/11/2017</td>
                  <td>Leganes</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>17/12/2017</td>
                  <td>Deportivo La Coruna</td>
                  <td>La Liga</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>22/12/2018</td>
                  <td>Celta Vigo</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>20/01/2019</td>
                  <td>Leganes</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>08/07/2020</td>
                  <td>Espanyol</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>01/10/2020</td>
                  <td>Celta Vigo</td>
                  <td>La Liga</td>
                  <td>Gk rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>04/10/2020</td>
                  <td>Sevilla</td>
                  <td>La Liga</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>14/09/2010</td>
                  <td>Panathinaikos</td>
                  <td>Champions League</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>03/04/2012</td>
                  <td>AC Milan</td>
                  <td>Champions League</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>24/02/2015</td>
                  <td>Man City</td>
                  <td>Champions League</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>12/09/2017</td>
                  <td>Juventus</td>
                  <td>Champions League</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>28/02/2007</td>
                  <td>Real Zaragoza</td>
                  <td>Copa del Rey</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>10/11/2010</td>
                  <td>AD Cueta</td>
                  <td>Copa del Rey</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>07/02/2017</td>
                  <td>Atletico Madrid</td>
                  <td>Copa del Rey</td>
                  <td>GK Rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>17/01/2019</td>
                  <td>Levante</td>
                  <td>Copa del Rey</td>
                  <td>Deflected</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>12/08/2018</td>
                  <td>Sevilla</td>
                  <td>Supercopa de Espana</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>09/07/2007</td>
                  <td>Peru</td>
                  <td>Copa America</td>
                  <td>GK rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>06/09/2011</td>
                  <td>Nigeria</td>
                  <td>Int. Friendly</td>
                  <td>GK rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>06/02/2013</td>
                  <td>Sweden</td>
                  <td>Int. Friendly</td>
                  <td>GK rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>04/06/2014</td>
                  <td>Trinidad</td>
                  <td>Int. Friendly</td>
                  <td>Post rebound</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
                <tr>
                  <td>11/12/2014</td>
                  <td>Croatia</td>
                  <td>Int. Friendly</td>
                  <td>Opta</td>
                  <td><span role="img" aria-label="warning">⚠️</span> Missing</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2>Issues with Opta</h2>

          <h3>Opta Assist Issues</h3>

          <p>First of all, Opta are the best stats provider in the football industry today, but even they can make mistakes. I will continue to use Opta's assist numbers unless it is absolutely clear they have made a mistake.</p>

          <p>According to some of their <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/OptaJose/status/1276916910496649222">posts</a> on Twitter, Opta appear to have Messi on 2 fewer Barcelona assists than most other sources that use the Opta assist definition.</p>

          <p>I have been able to verify that the discrepancy lies somewhere in Messi's Copa del Rey assists - however upon checking all of them, I can confirm that they all fit the definition, with none of them appearing to be even relatively questionable. It's possible that Opta may not have covered certain Copa games throughout Messi's career, such as ones from earlier rounds, so may be missing statistics from these games.</p>

          <h3>Other Opta Issues</h3>

          <p>Another minor issue with Opta is that they have a Ronaldo goal vs Dortmund (24/10/2012) as inside the box - footage shows this was clearly outside the box.</p>

          </CopyBlock>
        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetRonaldoAllTimeStats(filter: {competition: {eq: "All Time Career"}}) {
      edges {
        node {
          competition
          freeKicks
        }
      }
    }
  }
`

export default InfoPage
